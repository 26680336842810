<template>
  <div class="custom-card-box custom-height-100p">
    <div style="padding: 10px;">
      <div class="death-img">
        <Carousel v-if="data.images && data.images.length > 0">
          <div v-for="(item, index) in data.images" :key="index" style="text-align: center">
            <img
              alt="img"
              :src="item.url"
              width="500"
              height="700"
            >
          </div>
        </Carousel>
        <img
          v-show="!data.images || data.images.length === 0"
          alt="img"
          src="/temp/death.png"
        >
      </div>

      <div class="divider" />

      <div class="life-info-box">
        <span class="life-info-title">生平简介：</span>
        <div v-if="data.life_info" class="life-info-content">
          {{ data.life_info }}
        </div>
        <div v-else class="life-info-content">
          <div class="no-life-info">
            <img src="/temp/mobile_no_data.png" alt="logo">
            <span> 暂无数据 </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findServiceOrderDeath } from '@/api/service_order_death'
import { Carousel } from 'ant-design-vue'

export default {
  name: 'MobileDeath',
  components: {
    Carousel
  },
  data() {
    return {
      data: {}
    }
  },
  computed: {
    hallId() {
      return parseInt(this.$route.query.hall_id)
    }
  },
  created() {
    this.fetchSearchOrderDeath()
  },
  methods: {
    fetchSearchOrderDeath() {
      findServiceOrderDeath({ hall_id: this.hallId }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.death-img {
  text-align: center;

  img {
    width: 500px;
    height: 700px;
  }
}

.divider {
  margin: 40px 0;
  border: #9e9e9ead 1px solid;
}

.life-info-box {
  .life-info-title {
    font-size: 48px;
    font-weight: bold;
  }

  .life-info-content {
    overflow: auto;
    margin-top: 40px;
    padding: 20px;
    height: 800px;
    border: #9e9e9ead 1px solid;
    font-size: 48px;
    .no-life-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      height: 100%;

      img {
        width: 100px;
      }

      span {
        margin-top: 10px;
        font-size: 20px;
      }
    }
  }
}
/deep/ .ant-carousel .slick-slide img {
  display: inline;
}
</style>

